
import { IonPage, IonContent } from "@ionic/vue";
import ExploreContainer from "@/components/ExploreContainer.vue";

export default {
  name: "Home",
  components: {
    ExploreContainer,
    IonContent,
    IonPage,
  },
};
