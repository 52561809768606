<template>
  <ion-page>
<!-- <ion-tab tab="about"> -->
    <!-- <ion-header>
      <ion-toolbar>
        <ion-title>{{ tabName }}</ion-title>
      </ion-toolbar>
    </ion-header> -->
    <ion-content :fullscreen="true">
      <!-- <HomeContainer name="Tab 1 page" /> -->
      <ExploreContainer>
        <h2>Developed by Jaime Hidalgo</h2>
        <p>Email: <a href="mailto:jaimehidalgo2+banatu@gmail.com">jaimehidalgo2+banatu@gmail.com</a></p>
        <p>GitHub: <a href="https://github.com/jaimehrubiks">Jaimehrubiks</a></p>
      </ExploreContainer>
    </ion-content>
<!-- </ion-tab> -->
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent } from "@ionic/vue";
import ExploreContainer from "@/components/ExploreContainer.vue";

export default {
  name: "Home",
  components: {
    ExploreContainer,
    IonContent,
    IonPage,
  },
};
</script>